<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 205px)"
      class="table-padding-2-no-top"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader">
              <DateRangeFilter
                :label="$t('labels.create_time')"
                :placeholder="$t('labels.create_time')"
                name="created_at"
                sort-name="created_at"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.employee')"
                :placeholder="$t('labels.employee')"
                name="full_name"
                sort-name="full_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.account')"
                :placeholder="$t('labels.account')"
                name="email"
                sort-name="email"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectWarehouse
                :label="$t('labels.warehouse_1')"
                :placeholder="$t('labels.warehouse_1')"
                name="id_warehouse"
                sort-name="warehouse_code"
                :auto-select="false"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectPos
                :label="$t('labels.pos')"
                :placeholder="$t('labels.pos')"
                name="id_pos"
                sort-name="pos_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
                :auto-select="false"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :label="$t('labels.department')"
                :placeholder="$t('labels.department')"
                name="id_department"
                sort-name="id_department"
                :options="departments"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :label="$t('labels.position')"
                :placeholder="$t('labels.position')"
                name="id_position"
                sort-name="id_position"
                :options="positions"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="width: 120px">
              <SelectFilter
                :label="$t('labels.status')"
                :placeholder="$t('labels.status')"
                :options="statusOptions"
                name="status"
                sort-name="status"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id" class="">
            <td class="pl-3">{{ formatDateTime(item.created_at) }}</td>
            <td class="pl-3">
              <span
                class="text-decoration-underline info--text cursor-pointer"
                @click="showDetailDialog(item)"
                >{{ item.full_name }}</span
              >
            </td>
            <td class="pl-3">{{ item.email }}</td>
            <td class="pl-3">
              {{ item.customer_sub_account_warehouse_codes }}
            </td>
            <!-- <td class="pl-3">
              {{ item.customer_sub_account_material_warehouse_codes }}
            </td> -->
            <td class="pl-3">{{ item.customer_sub_account_pos_codes }}</td>
            <td class="pl-3"></td>
            <td class="pl-3"></td>
            <td class="text-center">
              <v-switch
                class="switch-center"
                v-model="item.active"
                :value="true"
                @change="switchStatus(item)"
                :key="`employee_${item.id}_${item.active}`"
              ></v-switch>
            </td>
            <td>
              <v-btn
                color="success"
                outlined
                x-small
                block
                @click="showPermissionDialog(item)"
              >
                {{ $t("labels.set_permission") }}
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="pt-3 w-100 d-flex align-center justify-space-between">
      <div>&nbsp;</div>
      <div>
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="5"
        ></v-pagination>
      </div>
      <div class="fs-14px">
        <a
          class="cursor-pointer text-decoration-underline"
          :href="registerLink()"
          target="_blank"
        >
          {{ $t("labels.register_link") }}
        </a>
        &nbsp;
        <v-icon @click="copyLink" class="cursor-pointer" color="error">
          mdi-content-copy
        </v-icon>
        <div ref="copyRef" style="display: none">{{ registerLink() }}</div>
      </div>
    </div>

    <v-dialog v-model="detailDialog" persistent max-width="640px">
      <SubAccountDetail
        v-if="detailDialog && employee && employee.id"
        :employee="employee"
        :departments="departments"
        :positions="positions"
        @closeDialog="hideDetailDialog"
        @refreshData="getSubAccount"
      />
    </v-dialog>

    <v-dialog v-model="permissionDialog" max-width="1200px">
      <SubAccountPermission
        v-if="permissionDialog && employee && employee.id"
        :employee="employee"
        @closeDialog="hidePermissionDialog"
        @refreshData="getSubAccount"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { CUSTOMER_PERMISSIONS } from "@/libs/const";

export default {
  name: "SubAccount",
  components: {
    SelectFilter: () => import("@/components/table/SelectFilter"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    SubAccountDetail: () => import("@/components/account/SubAccountDetail"),
    SubAccountPermission: () =>
      import("@/components/account/SubAccountPermission"),
    SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    SelectPos: () => import("@/components/table/SelectPos"),
    // SelectMaterialWarehouse: () => import("@/components/table/SelectMaterialWarehouse"),
  },
  data: () => ({
    isLoading: false,
    page: 1,
    totalPage: 1,
    totalItem: 0,
    items: [],
    permissions: [...CUSTOMER_PERMISSIONS],
    filters: {},
    departments: [],
    positions: [],
    employee: {},
    detailDialog: false,
    permissionDialog: false,
    statusOptions: [
      {
        text: "Active",
        value: 10,
      },
      {
        text: "Inactive",
        value: 0,
      },
    ],
  }),
  watch: {
    page() {
      this.getSubAccount();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getSubAccount();
      },
      deep: true,
    },
  },
  mounted() {
    this.getSubAccount();
    this.getDepartmentAndPosition();
  },
  methods: {
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    showDetailDialog(employee) {
      this.employee = { ...employee };
      this.detailDialog = true;
    },
    hideDetailDialog() {
      this.employee = {};
      this.detailDialog = false;
    },
    showPermissionDialog(employee) {
      this.employee = { ...employee };
      this.permissionDialog = true;
    },
    hidePermissionDialog() {
      this.employee = {};
      this.permissionDialog = false;
    },
    registerLink() {
      const { me } = window;
      const id = me.customer && me.customer.id;
      const code = this.encodeNumber(id);
      return `${process.env.VUE_APP_WEB_V1_URL}/register?c=${code}`;
    },
    copyLink() {
      this.$nextTick(() => {
        this.$vToastify.success(this.$t("messages.copy_success"));
        this.copyText(this.$refs.copyRef.textContent);
      });
    },
    async getDepartmentAndPosition() {
      const { data } = await httpClient.post("/list-department-position");
      this.departments = [...data.departments].map((e) => ({
        value: e.id,
        text: this.$i18n.locale === "vi" ? e.department : e.department_en,
      }));
      this.positions = [...data.positions].map((e) => ({
        value: e.id,
        text: this.$i18n.locale === "vi" ? e.position : e.position_en,
      }));
    },
    async switchStatus(item) {
      if (!confirm(this.$t("messages.are_you_sure_update_status_employee"))) {
        this.getSubAccount();
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/update-sub-account-status", {
          id: item.id,
          status: item.active ? 10 : 0,
        });
        this.isLoading = false;
        this.getSubAccount();
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    async getSubAccount() {
      try {
        const { data } = await httpClient.post("/get-sub-account", {
          ...this.filters,
          page: this.page,
        });
        this.totalPage = data.totalPage;
        this.totalItem = data.total;
        this.items = [...data.rows].map((item) => {
          item.active = item.status === 10;
          /*item.permission = {}
                    item.editing = false
                    const permissions = item.permissions && item.permissions.split(';') || []
                    this.permissions.forEach(permission => {
                      item.permission[permission] = !!permissions.includes(permission);
                    })*/
          return item;
        });
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    async updateSubAccount(item) {
      if (!item.id && (!item.email || !this.isUsernameValid(item.email))) {
        this.$vToastify.warning(this.$t("messages.username_invalid"));
        return false;
      }
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/update-sub-account", item);
        this.isLoading = false;
        this.getSubAccount();
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
